<template>
  <div class="box is-shadowless">

  </div>
</template>

<script>
  import { defineAsyncComponent, toRefs, reactive } from "vue";


  export default {
    name: 'Activity',

    setup () {



      return {

      }
    }

  }
</script>

<style lang="scss" scoped>

</style>
